<template>
    <div>
        <base-header base-title="Daftar Perujuk"></base-header>
        <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                     <base-title :head-title="headTitle"></base-title>
                     <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <!-- <auto-refresh :table-name="tableName" :on-refresh="fetch"></auto-refresh>&nbsp;&nbsp; -->
                            <template v-if="checkPermission('RegistrasiResource.POST.RegistrasiByInternal')">
                            <base-button-modal
                                :button-text="modalText"
                                :button-icon="modalIcon"
                                :button-name="modalName"
                                :onButtonModalClicked="showModal"
                            ></base-button-modal>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="kt-portlet__body" >
                    <!-- <data-tables :table="table"></data-tables> -->
                    <v-server-table  
                      :columns="columns"
                      :options="options" 
                      ref="table_perujuk"
                      @loaded="onLoaded(0)"
                      @loading="onLoaded(1)"
                  >
                      <div slot="action" slot-scope="{ row }">
                          <btn-action 
                            :rowData="row"
                            :perujukVue="perujukVue"></btn-action>
                      </div>
                      <div v-if="isLoading" slot="afterBody" >
                          <h6 class="txt"> loading..</h6>
                      </div>
                  </v-server-table>
                </div>
                <!-- <div v-else class="m-auto p-5 d-flex flex-row h-100">
                    <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
                    <h5 class="my-auto pl-5">Loading ...</h5>
                </div> -->
                <form-modal
                    v-if="isShowModal"
                    :show-modal.sync="isShowModal"
                    @event="fetch"
                    :modal-name="modalName"
                    :modal-data="dataEdit"
                ></form-modal>
                <!-- <form-edit
                    v-if="isShowEditModal"
                    :show-modal.sync="isShowEditModal"
                    @event="fetch"
                    :modal-name="modalEdit"
                    :modal-data="dataEdit"
                ></form-edit> -->
            </div>
        </div>
        <modal-show-link :link-data="linkPerujuk" v-if="isShowLink" @setCloseModal="linkClose"/>
    </div>
</template>

<script>
import ModalShowLink from './_components/ModalShowLink.vue'
import CheckPermission from "./../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import BaseHeader from "./../_base/BaseHeader";
import BaseTitle from "./../_base/BaseTitle";
// import AutoRefresh from "./../_general/AutoRefresh";
import BaseButtonModal from "./../_base/BaseButtonModal";
import DataTables from "./../_table/DataTables";
import BtnAction from "./_components/BtnAction";
import LoadSpinner from "./../_general/LoadSpinner";
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
const PerujukRepository = RepositoryFactory.get("perujuk");
import TipeTelepon from "./../../constants/tipe-telepon-enum";
export default {
  components: {
    BaseHeader,
    BaseTitle,
    // AutoRefresh,
    BaseButtonModal,
    LoadSpinner,
    DataTables,
    BtnAction,
    ModalShowLink,
    FormModal: () => ({
      component: new Promise(function(resolve, reject) {
        setTimeout(function() {
          resolve(
            import(/* webpackChunkName: "modal-registrasi-perujuk" */ "./_components/FormModal.vue")
          );
        }, 1000);
      }),
      loading: LoadSpinner
    }),
    FormEdit: () => ({
      component: new Promise(function(resolve, reject) {
        setTimeout(function() {
          resolve(
            import(/* webpackChunkName: "modal-edit-perujuk" */ "./_components/FormEdit.vue")
          );
        }, 1000);
      }),
      loading: LoadSpinner
    })
  },
  data() {
    var vx = this;
    return {
      isLoading: false,
      columns: ['id', 'userId', 'firstName', 'lastName', 'kategoriNama', 'telepon', 'action'],
      options: {
        filterable: ['firstName', 'lastName', 'kategoriNama', 'telepon'],
        headings: {
          id: "Perujuk_Id",
          userId: "User_Id",
          firstName: "Nama Depan",
          lastName: "Nama Belakang",
          kategoriNama: "Kategori",
          telepon: "Telepon Utama",
          action: "Actions"
        },
        debounce: 750,
        filterByColumn: true,
        listColumns: {
          kategoriNama: [
                        {
                          id: 'AGEN ASURANSI',
                          text: 'AGEN ASURANSI'
                        },
                        {
                          id: 'DOKTER UMUM',
                          text: 'DOKTER UMUM'
                        },
                        {
                          id: 'DOKTER SPESIALIS',
                          text: 'DOKTER SPESIALIS'
                        },
                        {
                          id: 'AGEN KOMUNITAS',
                          text: 'AGEN KOMUNITAS'
                        },
                        {
                          id: 'AGEN PERUSAHAAN',
                          text: 'AGEN PERUSAHAAN'
                        },
                        {
                          id: 'BIDAN',
                          text: 'BIDAN'
                        }
                    ]
        },
        requestFunction (data) {
          let page = {};
          page.sort= 'createdDate,desc'
          page.page = data.page-1;
          page.size = data.limit;
          const query = helpers.mapperPerujukQuery(data.query, page)
          return PerujukRepository.getPerujuksWithQeury(query)
            .catch(function(e) {
              this.dispatch('error', e);
            })
        },
        responseAdapter: (resp) => {
          return {
            data: resp.data,
            count: resp.headers['x-total-count'] ? resp.headers['x-total-count'] : resp.headers['Content-Length'] ? resp.headers['Content-Length'] : resp.data.length
          }
        },
        templates: {
          telepon(h, row) {
            const filtered = row.teleponPerujuks? row.teleponPerujuks.filter(
                x => x.tipe == TipeTelepon.HP_PRIMARY
              ) : [];
              return filtered.length > 0
                ? "0" +
                    filtered[0].nomor.substr(2, filtered[0].nomor.length - 1)
                : "-";
          },
        }
      },
      isLoaded: false,
      isShowModal: false,
      isShowEditModal: false,
      isShowLink: false,
      dataEdit: null,
      linkPerujuk: '',
      headTitle: "Perujuk",
      tableName: "table_perujuk",
      modalText: "Daftar Perujuk",
      modalName: "modal_perujuk",
      modalEdit: "modal_edit_perujuk",
      modalIcon: "flaticon2-add-1",
      perujukVue: null,
      table: {
        tableName: "table_perujuk",
        tableDataResult: [],
        tableColumnDefs: [
          {
            defaultContent: "-",
            targets: "_all"
          },
          {
            targets: 5,
            render: function(data, type, row, meta) {
              const filtered = data.filter(
                x => x.tipe == TipeTelepon.HP_PRIMARY
              );
              return filtered.length > 0
                ? "0" +
                    filtered[0].nomor.substr(2, filtered[0].nomor.length - 1)
                : "-";
            }
          },
          {
            targets: -1,
            responsivePriority: 1
          }
        ],
        tableColumns: [
          {
            title: "Perujuk_Id",
            data: "id"
          },
          {
            title: "User_Id",
            data: "userId"
          },
          {
            title: "Nama Depan",
            data: "firstName"
          },
          {
            title: "Nama Belakang",
            data: "lastName"
          },
          {
            title: "Kategori",
            data: "kategoriNama"
          },
          {
            title: "Telepon Utama",
            data: "teleponPerujuks"
          },
          {
            title: "Actions",
            data: "id",
            createdCell: function(cell, cellData, rowData, rowIndex, colIndex) {
              let btnAction = Vue.extend(BtnAction);
              let instance = new btnAction({
                propsData: {
                  perujukVue: vx,
                  rowData: rowData
                }
              });
              instance.$mount();
              $(cell)
                .empty()
                .append(instance.$el);
            }
          }
        ],
        tableFilter: [
          {
            title: "Nama Depan",
            type: "text"
          },
          {
            title: "Nama Belakang",
            type: "text"
          },
          {
            title: "Kategori",
            type: "combobox"
          },
          {
            title: "Telepon Utama",
            type: "text"
          },
          {
            title: "Actions",
            type: "actions"
          }
        ]
      }
    };
  },
  watch: {
    isShowModal: function(val) {
      if (!val) {
        this.dataEdit = null;
      }
    },
    // isShowEditModal: function(val) {
    //   if (!val) {
    //     this.dataEdit = null;
    //   }
    // }
  },
  mounted () {
    this.perujukVue = this
  },
  methods: {
    onLoaded: function(t) {
      t == 0 ? this.isLoading = false : this.isLoading = true  
    },
    linkClose () {
      this.isShowLink = false
    },
    // showEditModal: function(){
    //     this.isShowEditModal = true;
    // },
    showModal: function() {
      this.isShowModal = true;
    },
    async fetch() {
      this.$refs['table_perujuk'].refresh();
    },
    // async fetch(resolve) {
    //   new Promise((resolve, reject) => {
    //     PerujukRepository.get({}, resolve, reject);
    //   })
    //     .then(response => {
    //       const data = response.data;
    //       if (this.isLoaded) {
    //         var datatable = $("#" + this.tableName).DataTable();
    //         datatable.clear();
    //         datatable.rows.add(data);
    //         datatable.draw("full-hold");
    //         if (resolve !== undefined) {
    //           resolve(data);
    //         }
    //       } else {
    //         this.table.tableDataResult = data;
    //         this.isLoaded = true;
    //       }
    //     })
    //     .catch(error => {
    //       var msg = error.message ? error.message : error;
    //       this.showToastError("Perujuk : " + msg);
    //     });
    // },
    checkPermission: function(method) {
      return checkPermission.isCanAccess(method);
    },
    showToastError: function(message) {
      toastr.error(message);
    }
  },
  // created() {
  //   this.fetch();
  // }
};
</script>

<style>
</style>

